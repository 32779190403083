<script>
  let name = "";
  let phone = "";
  let email = "";
	let message = "";
	
	var host = "https://www.ra-don.ru";
		
  function Send() {

			axios
      .post(host + "/webform_rest/submit?_format=json", {
					webform_id: "forma_khostinga",
					name: name,
					email: email,
					phone: phone,
					message: message	
      })
      .then(response => {
				this.response = JSON.stringify(response, null, 2);
				alert("Ваша заявка отправлена");
      });
  }
</script>

<div class="footer-form">
  <div class="karla position-relative" id="form-bottom">
    <div class="form-row">
      <div class="col">
        <input
          class="form-control"
          v-model="name"
          type="text"
          placeholder="Имя"
          required
          data-animate="fadeInUp"
          data-delay=".45"
          bind:value={name} />
      </div>
      <div class="col">
        <input
          class="form-control"
          type="email"
          v-model="mail"
          placeholder="Email"
          required
          data-animate="fadeInUp"
          data-delay=".5"
          bind:value={email} />
      </div>
    </div>
    <input
      class="form-control"
      type="text"
      v-model="phone"
      placeholder="Телефон"
      required
      data-animate="fadeInUp"
      data-delay=".55"
      bind:value={phone} />
    <textarea
      class="form-control w-100"
      v-model="message"
      rows="4"
      placeholder="Сообщение"
      required
      data-animate="fadeInUp"
      data-delay=".6"
      bind:value={message} />
    <input
      type="submit"
      class="btn btn-transparent poppins"
      value="Отправить"
      data-animate="fadeInUp"
      data-delay=".65"
      on:click={Send} />
  </div>
  <div class="form-response" />
</div>
